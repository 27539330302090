<template>
    <div class="modal" id="cart">
        <div class="modal-backdrop" @click="reloadPage()"></div>
        <div class="modal-block" @click.stop>
            <button class="modal-btn md" @click="reloadPage()">
                <img src="@/assets/img/close.webp" alt="close" />
            </button>
            <div class="modal-body">
                <div class="modal-body__container">
                    <template v-if="totalCountCart()">
                        <buttons-toggle
                            v-model="activeTab"
                            nameInput="item"
                            v-if="isPayment"
                            @onChange="tabShow"
                            @addressChange="this.$emit('addressChange')"
                        />
                        <template v-if="isOrderProducts">
                            <h3 class="count-products">
                                {{ totalCountCart() }} товара на сумму {{ totalPriceCart() }}{{ rub }}
                            </h3>
                            <div class="products">
                                <template v-for="product in cartItems" :key="product.id">
                                    <div class="product" v-if="product.count">
                                        <div class="product__img">
                                            <img
                                                :src="
                                                    product.image ? product.image : require(`@/assets/img/no-photo.jpg`)
                                                "
                                                alt="image"
                                            />
                                        </div>
                                        <div class="product__body">
                                            <div class="product__head">
                                                <h4 class="product__title">{{ product.name }}</h4>
                                                <span class="product__weight">{{ product.weight }} г</span>
                                            </div>
                                            <div class="product__bottom">
                                                <div class="product__price">
                                                    {{ product.price * product.count }} {{ rub }}
                                                </div>
                                                <div class="counter">
                                                    <span class="minus" @click="$emit('minusToCart', product)">-</span>
                                                    <span class="count">{{ product.count }}</span>
                                                    <span class="plus" @click="$emit('plusToCart', product)">+</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div class="strokes">
                                <div class="stroke">
                                    <span
                                        ><img :src="require(`@/assets/img/svg/devices.svg`)" alt="img" /> Количество
                                        приборов</span
                                    >
                                    <div class="counter">
                                        <span class="minus" @click="countDevDecr">-</span>
                                        <span class="count">{{ countDev }}</span>
                                        <span class="plus" @click="countDevIncr">+</span>
                                    </div>
                                </div>
                                <!--@click="isInputPromocode = true"-->
                                <div class="stroke">
                                    <div class="inputPromocode" v-if="isInputPromocode || promo_code">
                                        <div class="block-input">
                                            <span
                                                v-if="isCheckPromocode || promo_code"
                                                :class="
                                                    promocode.active && promocode.type !== 'Product'
                                                        ? 'promocode'
                                                        : 'error'
                                                "
                                                >{{ inputPromocode }}</span
                                            >
                                            <input
                                                v-else
                                                type="text"
                                                name="promocode"
                                                placeholder="Введите промокод"
                                                v-model="inputPromocode"
                                                @input="inputValidPromocode"
                                                :maxlength="maxLength"
                                                :disabled="isCheckPromocode ? true : false"
                                            />
                                            <div class="check_promocode" :class="check_promocode !== '' ? 'error' : ''">
                                                {{ check_promocode }}
                                            </div>
                                            <div
                                                v-if="promocode.active && promocode.type !== 'Product'"
                                                class="discount"
                                            >
                                                <!-- Скидка 50% при заказе от 599р -->
                                                <div class="promocode_active">
                                                    <span>Скидка по промокоду</span>
                                                    <span v-if="promocode.type === 'FlexibleSum'">
                                                        {{ promocode.amount }} %
                                                    </span>
                                                    <span v-else-if="promocode.type === 'Percent'">
                                                        {{ promocode.amount }} %
                                                    </span>
                                                    <span v-else> {{ amount }} {{ rub }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            v-if="!isCheckPromocode && !promo_code"
                                            @click="applyPromocode(inputPromocode)"
                                            class="btn btn-apply"
                                        >
                                            Применить
                                        </button>
                                        <button v-else @click="removePromoCode" class="btn btn-remove">Удалить</button>
                                    </div>
                                    <template v-else>
                                        <span>Ввести промокод</span>
                                        <button class="btn-back">
                                            <svg
                                                width="10"
                                                height="18"
                                                viewBox="0 0 10 18"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M8.52639 1.4209L1.78955 8.99985L8.52639 16.5788"
                                                    stroke="#C8C8C8"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        </button>
                                    </template>
                                </div>
                            </div>
                            <recom-products
                                title="Добавить к заказу?"
                                @refMinusToCart="refMinusToCart"
                                @refPlusToCart="refPlusToCart"
                                @refAddToCart="refAddToCart"
                                :ifSouse="true"
                            />
                            <div class="total-price">
                                <div class="total-price__shipping" v-if="activeTab === 1">
                                    <span>Доставка</span>
                                    <span>{{ totalSumDelivery() }} {{ rub }}</span>
                                </div>
                                <div class="total-price__total">
                                    <span>Итого</span>
                                    <span
                                        >{{
                                            activeTab === 1 ? totalPriceCart() + totalSumDelivery() : totalPriceCart()
                                        }}
                                        {{ rub }}</span
                                    >
                                </div>
                                <span class="error" v-if="error">
                                    {{ error }}
                                </span>
                                <btn-name
                                    :btnName="
                                        activeTab === 1
                                            ? totalPriceCart() > minSum
                                                ? 'Оформить заказ'
                                                : textDisabled
                                            : 'Оформить заказ'
                                    "
                                    btnClass="btnCart"
                                    :disabled="activeTab === 1 ? (totalPriceCart() > minSum ? false : true) : false"
                                    @click="nextStep()"
                                />
                            </div>
                        </template>
                        <template v-else>
                            <div class="block-payment" v-if="isPayment">
                                <div class="body">
                                    <h4 class="head-title">Время готовности</h4>
                                    <input type="hidden" v-model="shipping_type" />
                                    <div class="time-shipping">
                                        <button
                                            class="time-shipping__btn"
                                            v-for="shipping in shippings"
                                            :key="shipping.id"
                                            :class="shipping_type === shipping.value ? 'active' : ''"
                                            @click="shipping_type = shipping.value"
                                        >
                                            {{ shipping.name }}
                                        </button>
                                    </div>
                                    <input type="hidden" v-model="time_interval" />
                                    <div class="time-interval" v-if="shipping_type === 'later'">
                                        <div
                                            class="time-interval__time"
                                            :class="time_interval === interval ? 'active' : ''"
                                            @click="time_interval = interval"
                                            v-for="(interval, index) in intervals"
                                            :key="index"
                                        >
                                            {{ interval }}
                                        </div>
                                    </div>
                                </div>
                                <div class="body" v-if="activeTab === 2">
                                    <h4 class="head-title">Способ получения</h4>
                                    <input type="hidden" v-model="delivery_type" />
                                    <div class="methods">
                                        <div
                                            class="methods__block"
                                            :class="delivery_type === label.value ? 'active' : ''"
                                            v-for="label in labels"
                                            :key="label.id"
                                            @click="delivery_type = label.value"
                                        >
                                            <div class="methods__img">
                                                <img :src="require(`@/assets/img/3d/${label.img}`)" alt="img" />
                                            </div>
                                            <h4 class="methods__title" :value="label.value">{{ label.name }}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="body" v-if="!token">
                                    <h4 class="head-title">Оплата</h4>
                                    <!-- <pays /> -->
                                    <div class="block-input">
                                        <MaskInput
                                            v-model="vmPhone"
                                            mask="+7 ### ### ## ##"
                                            placeholder="Введите номер телефона"
                                            @input="inputPhone"
                                            :value="'+7 ' + valuePhone"
                                        />
                                    </div>
                                </div>
                                <span class="error" v-if="error">
                                    {{ error }}
                                </span>
                                <div class="body">
                                    <h4 class="head-title">Комментарий к заказу</h4>
                                    <textarea
                                        name="comment-order"
                                        id="comment-order"
                                        placeholder="Например, не добавлять соус в кыстыбургер"
                                        v-model="comment"
                                    ></textarea>
                                </div>
                                <div class="total-price">
                                    <div class="total-price__shipping">
                                        <span>{{ totalCountCart() }} товара на сумму</span>
                                        <span>{{ totalPriceCart() }} {{ rub }}</span>
                                    </div>
                                    <div class="total-price__total">
                                        <span>К оплате</span>
                                        <span>{{ totalPriceCart() }} {{ rub }}</span>
                                    </div>
                                    <btn-name
                                        :btnName="
                                            activeTab === 1
                                                ? totalPriceCart() > minSum
                                                    ? 'Оформить заказ'
                                                    : textDisabled
                                                : 'Оформить заказ'
                                        "
                                        btnClass="btnCart"
                                        :disabled="activeTab === 1 ? (totalPriceCart() > minSum ? false : true) : false"
                                        @click="sendPayment()"
                                    />
                                </div>
                            </div>
                            <div class="status-order" v-else>
                                <div class="number-order">Заказ #{{ number_order }}</div>
                                <h4 class="status-title">Статус заказа</h4>
                                <steps-order :status="status_order" />
                                <div class="personal-offers__block">
                                    <div class="personal-offers__body">
                                        <div class="personal-offers__stocks">
                                            Скидка 20% на первый<br />
                                            заказ в приложении
                                        </div>
                                        <div class="personal-offers__promocode">Промокод: МИНУС20</div>
                                        <button class="personal-offers__btn btn" @click="hrefOpen()">Скачать</button>
                                    </div>
                                    <div class="personal-offers__img">
                                        <img src="@/assets/img/stocks/small/1.png" alt="img" />
                                    </div>
                                </div>
                                <h4 class="status-title">Детали заказа</h4>
                                <div class="products">
                                    <template v-for="item in items" :key="item.id">
                                        <div class="product" v-if="item.count">
                                            <div class="product__img">
                                                <img :src="item.image" alt="image" />
                                            </div>
                                            <div class="product__body">
                                                <div class="product__head">
                                                    <h4 class="product__title">{{ item.name }}</h4>
                                                    <span class="product__weight">{{ item.weight }} г</span>
                                                </div>
                                                <div class="product__bottom">
                                                    <div class="product__price">{{ item.price }} {{ rub }}</div>
                                                    <span class="count">{{ item.count }} шт</span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <div class="address">
                                    <div class="address__title">
                                        Адрес {{ activeTab === 2 ? "самовывоза" : "доставки" }}
                                    </div>
                                    <div class="address__street">
                                        <template v-if="activeTab == 1">
                                            <span
                                                >{{ getDeliveryData?.address.address }}, кв.{{
                                                    getDeliveryData?.address.flat
                                                }}</span
                                            >
                                        </template>
                                        <template v-else>
                                            <span>{{ getPickupData?.address }}</span>
                                            <span>{{ delivery_type === "packed_pickup" ? "С собой" : "В зале" }}</span>
                                        </template>
                                    </div>
                                </div>
                                <div class="time-order">
                                    <div class="time-order__order">
                                        <span>Время заказа</span>
                                        <span>{{ nowDate }} в {{ this.now.toLocaleTimeString().slice(0, -3) }}</span>
                                    </div>
                                    <div class="time-order__done">
                                        <span>Время выдачи</span>
                                        <span
                                            >{{ nowDate }} в
                                            {{
                                                shipping_type === "later"
                                                    ? time_interval.split("-")[1]
                                                    : fastDeliveryTime
                                            }}</span
                                        >
                                    </div>
                                </div>
                                <div class="total-price">
                                    <div class="total-price__shipping">
                                        <span>{{ totalCountCart() }} товара на сумму</span>
                                        <span>{{ totalPriceCart() }} {{ rub }}</span>
                                    </div>
                                    <div class="total-price__t-coins">
                                        <span>Начислим Т-коинов</span>
                                        <span>+{{ Math.floor(totalPriceCart() / 20) }}</span>
                                    </div>
                                    <div
                                        class="total-price__discounts"
                                        v-if="promocode.active && promocode.type !== 'Product'"
                                    >
                                        <span>Скидка по промокоду</span>
                                        <span v-if="promocode.type === 'FlexibleSum'"> {{ promocode.amount }} % </span>
                                        <span v-else-if="promocode.type === 'Percent'"> {{ promocode.amount }} % </span>
                                        <span v-else> {{ amount }} {{ rub }}</span>
                                    </div>
                                    <div class="total-price__total">
                                        <span>Итого</span>
                                        <div v-if="promocode.active && promocode.type !== 'Product'">
                                            <span v-if="promocode.type === 'FlexibleSum'">
                                                {{
                                                    activeTab === 1
                                                        ? totalPriceCart() -
                                                          (totalPriceCart() / 100) * Number(promocode.amount) +
                                                          totalSumDelivery()
                                                        : totalPriceCart() -
                                                          (totalPriceCart() / 100) * Number(promocode.amount)
                                                }}
                                                {{ rub }}
                                            </span>
                                            <span v-else-if="promocode.active && promocode.type === 'Percent'">
                                                {{
                                                    activeTab === 1
                                                        ? totalPriceCart() -
                                                          (totalPriceCart() / 100) * Number(promocode.amount) +
                                                          totalSumDelivery()
                                                        : totalPriceCart() -
                                                          (totalPriceCart() / 100) * Number(promocode.amount)
                                                }}
                                                {{ rub }}
                                            </span>
                                        </div>
                                        <div v-else>
                                            {{
                                                activeTab === 1
                                                    ? totalPriceCart() + totalSumDelivery()
                                                    : totalPriceCart()
                                            }}
                                            {{ rub }}
                                        </div>

                                        <!-- <span
                                            >{{
                                                activeTab === 1 ? totalPriceCart() + sumDelivery : totalPriceCart()
                                            }}
                                            {{ rub }}</span
                                        > -->
                                    </div>
                                    <button class="btn-bn btnCart" @click="isReview = true">Оставить отзыв</button>
                                </div>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <div class="modal-body__empty">
                            <div class="modal-body__cart-img md">
                                <img src="@/assets/img/3d/cart_empty.png" alt="cart" />
                            </div>
                            <div class="modal-body__cart-img mm">
                                <img src="@/assets/img/3d/cart.png" alt="cart" />
                            </div>
                            <div class="modal-body__message">
                                <p>В корзине пока</p>
                                <p>ничего нет</p>
                            </div>
                        </div>
                        <button class="modal-body__btn" disabled>{{ textDisabled }}</button>
                    </template>
                </div>
            </div>
        </div>
    </div>
    <alert
        v-if="isAlert && isOrderProducts"
        title="Способ заказа"
        :message="`При изменении адреса доставки\n или самовывоза может измениться\n наличие товара`"
        @close="isAlert = false"
        @showModal="this.$emit('showModal')"
    />
    <review v-if="isReview" @close="isReview = false" />
    <login-modal v-if="isLoginModal" @click="isLoginModal = false" @close="isLoginModal = false" :display="2" />
</template>

<script>
import axios from "axios";
import RecomProducts from "../RecomProducts.vue";
import StepsOrder from "../StepsOrder.vue";
import BtnName from "../UI/BtnName.vue";
import ButtonsToggle from "../UI/ButtonsToggle.vue";
import Alert from "@/components/popups/Alert.vue";
import Review from "../popups/Review.vue";
import LoginModal from "./LoginModal.vue";
//import Pays from "../UI/Pays.vue";
export default {
    components: { ButtonsToggle, RecomProducts, BtnName, StepsOrder, Alert, Review, LoginModal /*Pays*/ },
    props: ["product"],
    emits: ["minus-to-cart", "plus-to-cart", "add-to-cart", "addressChange", "showModal", "close"],
    data() {
        return {
            orderObj: {},
            isLoginModal: false,
            status_order: "",
            number_order: 0,
            sumDelivery: 99,
            vmPhone: "",
            minSum: 799,
            textDef: `Добавьте товары на `,
            rub: "₽",
            isAlert: false,
            isReview: false,
            items: [],
            totalPrice: 0,
            isInputPromocode: false,
            inputPromocode: JSON.parse(localStorage.getItem("promocode"))?.code ?? "",
            maxLength: 10,
            amount: "",
            promocode: {
                active: false,
                code: "",
                amount: null,
                description: null,
                validation: true,
                error: null,
                type: null,
            },
            isOrderProducts: true,
            isPayment: true,
            delivery_type: "pickup_on_tray",
            shipping_type: "fast",
            time_interval: "",
            chopsticks: 0,
            now: new Date(),
            now2: new Date(),
            token: localStorage.getItem("token"),
            labels: [
                { id: 1, name: "В зале", img: "with_him.png", value: "pickup_on_tray" },
                { id: 2, name: "С собой", img: "in_hall.png", value: "packed_pickup" },
            ],
            orderId: localStorage.getItem("orderId") ?? null,
            shop_id: 2,
            countDev: 1,
            activeTab: Number(localStorage.getItem("tabToggle")),
            shippings: [
                { id: 1, name: "Как можно скорее", value: "fast" },
                { id: 2, name: "Готовить позже", value: "later" },
            ],
            intervals: [],
            error: null,
            getDeliveryData: JSON.parse(localStorage.getItem("delivery_data")),
            getPickupData: JSON.parse(localStorage.getItem("pickup_data")),
            fastDeliveryTime: "",
            valuePhone: localStorage.getItem("phone") ?? "",
            valueName: localStorage.getItem("name") ?? "",
            order_type: localStorage.getItem("order_type"),
            bonuses: 0,
            percent: 0,
            isCheckPromocode: false,
            check_promocode: "",
            longitude: localStorage.getItem("longitude"),
            latitude: localStorage.getItem("latitude"),
            comment: window.location.href.indexOf("localhost") != -1 ? "это тестирование (игнорировать!)" : "",
            promo_code: JSON.parse(localStorage.getItem("promocode")) ?? null,
            shops: null,
        };
    },
    methods: {
        reloadPage() {
            if (this.$router.path !== window.location.href) {
                window.location = window.location.href.split("/?")[0];
            } else {
                window.location.reload();
            }
        },
        fetchShops() {
            axios
                .get("https://admin.tcode.online/api/projects/tubatay/shops/")
                .then((response) => {
                    this.shops = response.data;
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        fetchProducts() {
            if (this.getPickupData && this.shops) {
                const shop =
                    this.order_type == "by_pickup"
                        ? this.getPickupData
                        : this.shops.find((x) => x.id == this.getDeliveryData.shop);

                axios
                    .get(
                        `https://admin.tcode.online/api/projects/tubatay/shops/${shop.code}/products/?available=${this.order_type}/`,
                        {
                            headers: this.headers,
                        }
                    )
                    .then((response) => {
                        console.log(response);
                        if (response.status == 200) {
                            this.products = response.data.results;
                        }
                    })
                    .catch((e) => {
                        this.errors = e.message;
                    });
            }
        },
        refMinusToCart(product) {
            this.$emit("minusToCart", product);
        },
        refPlusToCart(product) {
            this.$emit("plusToCart", product);
        },
        refAddToCart(product) {
            this.$emit("addToCart", product);
            this.items.push(product);
        },
        inputPhone() {
            this.vmPhone && this.vmPhone.length >= 13 ? (this.error = "") : (this.error = "Введите телефон");
        },
        tabShow(tab) {
            this.$emit("showModal");
            this.$emit("addressChange");
            if (tab == 1) {
                if (this.getDeliveryData) {
                    this.isAlert = true;
                }
            } else {
                if (this.getPickupData) {
                    this.isAlert = true;
                }
            }
        },
        countDevDecr() {
            this.countDev > 1 ? this.countDev-- : (this.countDev = 1);
        },
        countDevIncr() {
            this.countDev++;
        },
        hrefOpen() {
            window.innerWidth <= 768
                ? window.open(
                      "https://apps.apple.com/ru/app/%D1%82%D1%8E%D0%B1%D0%B5%D1%82%D0%B5%D0%B9/id1594385089",
                      "_self"
                  )
                : window.open("/discounts", "_blank");
        },
        sendPayment() {
            if ((this.vmPhone && this.vmPhone.length < 13) || (this.valuePhone && this.valuePhone.length < 13)) {
                this.error = "Введите телефон";
            } else if (this.activeTab === 1 && !this.getDeliveryData) {
                this.error = "Укажите адрес доставки";
            } else if (this.activeTab === 2 && !this.getPickupData) {
                this.error = "Выберите точку самовывоза";
            } else {
                this.sendOrder();
            }
        },
        sendOrder() {
            this.isPayment = false;
            let headers = null;
            let items = [];
            let discount_code = this.promocode.code ?? null;

            if (this.token) {
                headers = {
                    Authorization: "Token " + this.token,
                };
            }

            for (let i = 0; i < this.items.length; i++) {
                if (this.items[i].type !== "discounted") {
                    let item = {};
                    item.quantity = this.items[i].count;
                    item.id = this.items[i].id;
                    items.push(item);
                }
            }

            if (items.length !== 0) {
                axios
                    .post(
                        `https://admin.tcode.online/api/projects/tubatay/orders/`,
                        {
                            shop_id:
                                this.order_type !== "by_pickup" ? this.getDeliveryData.shop : this.getPickupData.id,
                            items: items,
                            //bonuses: this.bonuses,
                            delivery_type: this.order_type !== "by_pickup" ? "DeliveryByCourier" : this.delivery_type,
                            express: true,
                            discount_code: discount_code,
                            order_date: this.now.toLocaleDateString(),
                            order_time_from: this.now.toLocaleTimeString().slice(0, -3),
                            order_time_to:
                                this.shipping_type === "later"
                                    ? this.time_interval.split("-")[1]
                                    : this.fastDeliveryTime,
                            app_version: "Сайт",
                            longitude: this.order_type !== "by_pickup" ? this.longitude : this.getPickupData.longitude,
                            latitude: this.order_type !== "by_pickup" ? this.latitude : this.getPickupData.latitude,
                            street: this.order_type !== "by_pickup" ? this.getDeliveryData.address.address : "",
                            house: "дом",
                            flat: this.order_type !== "by_pickup" ? this.getDeliveryData.address.flat : "",
                            floor: this.order_type !== "by_pickup" ? this.getDeliveryData.address.floor : "",
                            section: this.order_type !== "by_pickup" ? this.getDeliveryData.address.door : "",
                            //door_key: "Код домофона",
                            comment: this.comment,
                        },
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        console.log(response);
                        if (response.data.success) {
                            localStorage.setItem("orderId", response.data.order.id);
                            if (window.location.href.indexOf("localhost") == -1) {
                                setTimeout(() => {
                                    window.open(
                                        response.data.redirect_url,
                                        window.innerWidth <= 768 ? "_parent" : "_self"
                                    );
                                }, 5000);
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        inputValidPromocode() {
            this.inputPromocode = this.inputPromocode.toUpperCase();
        },

        applyPromocode(inputPromocode) {
            if (!inputPromocode) {
                this.check_promocode = "Введите промокод";
            } else {
                this.promocode.code = inputPromocode;
                this.checkDiscount();
                this.isCheckPromocode = true;
            }
        },

        checkDiscount() {
            //проверки скидки/промокода перед совершением заказа
            if ((this.items, this.promocode.code)) {
                let headers = null;

                let data = {
                    discount_code: this.promocode.code,
                    items: [],
                };

                if (this.token) {
                    headers = {
                        Authorization: "Token " + this.token,
                    };
                }

                for (let i = 0; i < this.items.length; i++) {
                    if (this.items[i].type !== "discounted") {
                        let item = {
                            id: this.items[i].id,
                            quantity: this.items[i].count,
                        };

                        data.items.push(item);
                    }
                }

                if (this.getPickupData && this.shops) {
                    const shop =
                        this.order_type == "by_pickup"
                            ? this.getPickupData
                            : this.shops.find((x) => x.id == this.getDeliveryData.shop);
                    axios
                        .post(
                            `https://admin.tcode.online/api/projects/tubatay/shops/${shop.code}/check-discount/`,
                            {
                                discount_code: data.discount_code,
                                items: data.items,
                            },
                            {
                                headers: headers,
                            }
                        )
                        .then((response) => {
                            console.log(response);
                            if (response.status == 200) {
                                this.promocode.active = true;
                                this.applyDiscount(response.data);
                                this.check_promocode = "";
                            }
                        })
                        .catch((error) => {
                            this.promocode.validation = false;
                            this.promocode.error = error.response?.data.error;
                            this.check_promocode = "Неверный промокод";
                        });
                }
            }
        },

        applyDiscount(data) {
            this.promocode.description = data.description;
            this.promocode.type = data.type;
            this.promocode.amount = data.amount;
            if (data.type === "Product") {
                const elem = this.items.find((item) => item.id === data.product.id);
                if (!elem) {
                    data.product.name = data.product.real_name;
                    data.product.type = "discounted";
                    data.product.this.items.push(data.product);
                    localStorage.setItem("cart", JSON.stringify(this.items));
                    this.totalPriceCart();
                }
            }
            localStorage.setItem("promocode", JSON.stringify(this.promocode));
        },

        checkPromocode() {
            let promocode = JSON.parse(localStorage.getItem("promocode")) ?? null;
            if (promocode) {
                this.promocode = promocode;
            }
        },

        removePromoCode() {
            this.isCheckPromocode = false;
            this.inputPromocode = "";
            this.check_promocode = "";
            this.promocode.validation = false;
            this.promocode.active = false;
            this.promo_code = null;
            localStorage.removeItem("promocode");
        },

        totalCountCart() {
            const array = JSON.parse(localStorage.getItem("cart")) ?? [];
            let items = [];
            if (array) {
                for (let i = 0; i < array.length; i++) {
                    if (array[i].type !== "discounted") {
                        items.push(array[i]);
                    }
                }
            }
            return items.reduce((acc, item) => acc + item.count, 0);
        },

        totalPriceCart() {
            const array = JSON.parse(localStorage.getItem("cart")) ?? [];
            let items = [];
            if (array) {
                for (let i = 0; i < array.length; i++) {
                    if (array[i].type !== "discounted") {
                        items.push(array[i]);
                    }
                }
            }
            return items.reduce((acc, item) => acc + item.price * item.count, 0);
        },

        nextStep() {
            if (this.token) {
                this.isLoginModal = false;
                this.vmPhone = this.valuePhone;
                this.isOrderProducts = false;
            } else {
                this.isLoginModal = true;
            }
        },
        totalSumDelivery() {
            return this.totalPriceCart() >= 1300 ? (this.sumDelivery = 0) : (this.sumDelivery = 99);
        },
    },
    computed: {
        nowDate() {
            const day = String(this.now.getDate()).padStart(2, "0");
            const month = String(this.now.getMonth() + 1).padStart(2, "0");
            const year = this.now.getFullYear();
            return `${day}.${month}.${year}`;
        },
        cartItems() {
            return this.items.map((item) => item);
        },
        textDisabled() {
            return this.textDef + this.minSum + this.rub;
        },
    },
    watch: {
        getPickupData(newValue) {
            if (newValue) {
                this.fetchProducts();
            }
        },

        getDeliveryData(newValue) {
            if (newValue) {
                this.fetchProducts();
            }
        },
        $route: {
            handler: function (search) {
                console.log(search);
                console.log(search.fullPath);
                if (search.fullPath === `/menu/?success=true`) {
                    this.isOrderProducts = false;
                    this.isPayment = false;
                }
                if (search.fullPath === `/menu/?success=false`) {
                    this.isOrderProducts = false;
                    this.status_order = "";
                }
            },
            deep: true,
            immediate: true,
        },
    },
    mounted() {
        this.fetchShops();
        this.fetchProducts();
        this.checkPromocode();
        let hour = Number(this.now.toLocaleTimeString().split(":")[0]);
        let minut = Number(this.now.toLocaleTimeString().split(":")[1]);
        if (hour >= 10 && hour <= 22) {
            for (let i = 0; i < 3; i++) {
                this.intervals.push(
                    String(hour + i).padStart(2, "0") +
                        ":" +
                        String(minut).padStart(2, "0") +
                        " - " +
                        String(hour + i + 1).padStart(2, "0") +
                        ":" +
                        String(minut).padStart(2, "0")
                );
            }
        } else {
            this.intervals.push("10:00 - 11:00", "11:00 - 12:00", "12:00 - 13:00");
        }
        this.time_interval = this.intervals[0];

        this.now.setTime(this.now.getTime() + 0 * 60 * 1000);
        this.now2.setTime(this.now.getTime() + 10 * 60 * 1000);
        this.fastDeliveryTime =
            String(this.now2.getHours()).padStart(2, "0") + ":" + String(this.now2.getMinutes()).padStart(2, "0");

        if (localStorage.getItem("cart")) {
            this.items = JSON.parse(localStorage.getItem("cart"));
        }
        // console.log(this.items);
        // console.log(this.orderId);
        let self = this;
        if (this.orderId) {
            //отслеживание статуса заказа
            axios
                .get(`https://admin.tcode.online/api/projects/tubatay/orders/` + this.orderId)
                .then(function (response) {
                    self.orderObj = response.data;
                    self.status_order = response.data.order_status.code;
                    self.number_order = response.data.order_number;
                    axios
                        .get("http://127.0.0.1:5000/payment/create/?amount=" + self.orderObj.full_price * 100)
                        .then((response) => {
                            self.onProccess = false;
                            console.log("TERMINAL: " + response.data);
                            if (response.data.success) {
                                console.log("TERMINAL: SUCCESS = " + response.data.success);
                                self.paymentError = false;
                                if (this.getPickupData && this.shops) {
                                    const shop =
                                        this.order_type == "by_pickup"
                                            ? this.getPickupData
                                            : this.shops.find((x) => x.id == this.getDeliveryData.shop);
                                    axios
                                        .post(
                                            `https://admin.tcode.online/api/projects/tubatay/shops/${shop.code}/order-confirm/`,
                                            {
                                                order_id: self.orderId,
                                                comment: "Заказ сделан через сайт Тюбетей",
                                            }
                                        )
                                        .then((response) => {
                                            self.onProccess = false;
                                            self.orderObj = response.data;

                                            axios.get(
                                                "http://127.0.0.1:5000/receipt/print/?text=" +
                                                    self.orderObj.receipt_text
                                            );

                                            setTimeout(function () {
                                                location.href = "/";
                                                localStorage.clear();
                                            }, 12000);
                                        })
                                        .catch((err) => {
                                            console.log("API: ERROR = " + err);
                                        });
                                }
                            }
                        })
                        .catch((err) => {
                            console.log("TERMINAL: ERROR = " + err);
                            self.paymentError = true;
                            self.onProccess = false;
                        });
                });
        }
        if (this.token) {
            //Профиль
            axios
                .get(`https://admin.tcode.online/api/projects/tubatay/profile/`, {
                    headers: {
                        Authorization: "Token " + this.token,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
            //бонусы
            axios
                .get(`https://admin.tcode.online/api/projects/tubatay/bonuses`, {
                    headers: {
                        Authorization: "Token " + this.token,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    this.bonuses = response.data.bonuses;
                    this.percent = response.data.percent;
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
};
</script>

<style lang="scss">
#cart {
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: flex-end;
    @media (max-width: 768px) {
        justify-content: center;
        overflow-x: hidden;
        background: none;
        backdrop-filter: none;
        z-index: 2;
    }
    .btn-back {
        min-width: 32px;
        height: 32px;
        border-radius: 50%;
        overflow: hidden;
        -webkit-box-shadow: 1px 1px 6px rgba(0, 45, 29, 0.05);
        box-shadow: 1px 1px 6px rgba(0, 45, 29, 0.05);
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        order: -1;
        &:hover,
        &:focus {
            background: #eee;
            transition: 0.2s ease 0.2s;
            svg {
                path {
                    stroke: #fff;
                    transition: 0.2s ease 0.2s;
                }
            }
        }
        @media (min-width: 769px) {
            display: none;
        }
    }
    .modal-body {
        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 2px;
        }
        &::-webkit-scrollbar-track {
            background: #99cfab;
            border-radius: 0;
        }
        &::-webkit-scrollbar-thumb {
            background: var(--green);
            border-radius: 0;
            cursor: pointer;
        }
        &::-webkit-scrollbar-thumb:hover {
            opacity: 0.5;
        }
    }
    button {
        &[disabled] {
            background: #c8c8c8;
            color: #7a7a7a;
        }
    }
    .tabs {
        @media (max-width: 768px) {
            height: 96px;
            margin-top: 20px;
        }
        .tabs-text {
            span {
                font-size: 12px;
                line-height: 20px;
                color: #000;
            }
        }
    }
    .recom-products {
        margin-right: -20px;
        padding-right: 20px;
        &__product {
            @media (max-width: 768px) {
                height: 104px;
            }
        }
        &__price {
            &.counter {
                background: #fff;
                color: var(--green);
                display: flex;
                justify-content: space-between;
                box-shadow: 0 3px 35px rgb(0, 45, 29, 0.1);
                min-width: 100%;
                min-height: 40px;
                padding: 0;
                .btn-to-cart {
                    min-width: 40px;
                    min-height: 40px;
                    border-radius: 12px;
                    background: #d9f5e4;
                    color: var(--green);
                }
                @media (max-width: 768px) {
                    min-height: 32px;
                    .btn-to-cart {
                        min-width: 32px;
                        min-height: 32px;
                    }
                }
            }
        }
    }
    .btnCart {
        min-width: 100%;
        min-height: 56px;
        border-radius: 30px !important;
        font-size: 16px !important;
        font-family: "Nunito Sans Bold" !important;
        margin-top: 20px;
    }
    .personal-offers__stocks {
        font-size: 14px;
        line-height: 22px;
        color: #1f2120;
        font-family: "Nunito Sans Bold";
    }
    .personal-offers__promocode {
        font-size: 14px;
        line-height: 24px;
        color: #c8c8c8;
        flex: 1 1 auto;
    }
    .personal-offers__block {
        display: flex;
        border-radius: 16px;
        overflow: hidden;
        box-shadow: 0 3px 35px rgb(0, 0, 0, 0.05);
        .btn {
            width: 120px;
            height: 32px;
            margin: 0;
        }
    }
    .personal-offers__body {
        padding: 15px;
        display: flex;
        flex-direction: column;
    }
    .personal-offers__img {
        min-width: 120px;
        max-width: 120px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    @media (max-width: 768px) {
        justify-content: center;
        overflow-x: hidden;
    }
    .inputPromocode {
        width: 100%;
        display: flex;
        .block-input {
            flex: 1 1 auto;
            display: flex;
            justify-content: center;
            flex-direction: column;
            position: relative;
            text-align: left;
            .promocode {
                color: var(--green);
            }
            .error {
                color: var(--red);
            }
            .check_promocode {
                font-size: 8px;
                line-height: 12px;
                text-align: center;
                font-family: "Nunito Sans ExtraLight";
                position: absolute;
                top: 0;
                bottom: 0;
                right: 20px;
                display: flex;
                align-items: center;
            }
            .discount {
                font-size: 10px;
                line-height: 16px;
                color: #7a7a7a;
                margin-top: -5px;
                .promocode_active {
                    display: flex;
                    flex-direction: column;
                }
            }
            input {
                font-size: 14px;
                line-height: 24px;
                color: #333;
                flex: 1 1 auto;
                font-family: "Nunito Sans";
                background: none;
                outline: none;
                &::placeholder {
                    color: #c8c8c8;
                }
                &:focus {
                    outline: none;
                }
            }
        }
        .btn {
            min-width: 120px;
            max-width: 120px;
            height: 36px;
            border-radius: 16px;
            font-family: "Nunito Sans Bold";
            font-size: 14px;
            line-height: 22px;
            @media (max-width: 768px) {
                margin-left: -20px;
            }
        }
        .btn-apply {
            background: #d9f5e4;
            color: var(--green);
            &:hover,
            &:focus {
                background: #c5f7d9;
                transition: 0.2s ease 0.2s;
            }
        }
        .btn-remove {
            background: #f4f4f4;
            color: #c8c8c8;
            &:hover,
            &:focus {
                background: #c8c8c8;
                color: #f4f4f4;
                transition: 0.2s ease 0.2s;
            }
        }
    }
    .count-products {
        font-family: "Nunito Sans Bold";
        font-size: 18px;
        line-height: 24px;
        color: #1f2120;
    }
    .products {
        overflow-x: hidden;
        min-height: 300px;
        max-height: 300px;
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-track {
            background: #d9f5e4;
            border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background: var(--green);
            border-radius: 5px;
            cursor: pointer;
        }
        &::-webkit-scrollbar-thumb:hover {
            opacity: 0.5;
        }
        .product {
            width: 100%;
            height: 144px;
            border-radius: 16px;
            overflow: hidden;
            box-shadow: 0 3px 35px rgb(0, 45, 29, 0.05);
            margin-top: 10px;
            display: flex;
            @media (max-width: 768px) {
                height: 104px;
            }
            &__img {
                min-width: 144px;
                max-width: 144px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                @media (max-width: 768px) {
                    min-width: 104px;
                    max-width: 104px;
                    height: 104px;
                }
            }

            &__body {
                display: flex;
                flex-direction: column;
                padding: 10px;
                flex: 1 1 auto;
                @media (max-width: 768px) {
                    padding: 5px 10px;
                }
            }

            &__head {
                flex: 1 1 auto;
            }

            &__title {
                font-size: 14px;
                line-height: 24px;
                color: #1f2120;
                white-space: pre-line;
                @media (max-width: 768px) {
                    font-size: 12px;
                    line-height: 16px;
                    font-family: "Nunito Sans ExtraLight";
                }
            }

            &__weight {
                font-size: 10px;
                line-height: 16px;
                color: #bebebe;
            }

            &__bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &__price {
                font-family: "Nunito Sans Bold";
                font-size: 18px;
                line-height: 24px;
                color: #1f2120;
            }
        }
    }
    .counter {
        background: #d9f5e4;
        min-width: 86px;
        max-width: 86px;
        height: 36px;
        border-radius: 16px;
        font-family: "Nunito Sans Bold";
        font-size: 16px;
        line-height: 24px;
        color: var(--green);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        .minus,
        .plus {
            cursor: pointer;
        }
        @media (max-width: 768px) {
            min-width: 64px;
            max-width: 64px;
            height: 24px;
            border-radius: 10px;
            font-size: 12px;
            line-height: 18px;
        }
    }
    .strokes {
        .stroke {
            width: 100%;
            min-height: 56px;
            background: #fff;
            box-shadow: 0 3px 35px rgb(0, 45, 29, 0.05);
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
            font-family: "Nunito Sans Bold";
            font-size: 14px;
            line-height: 22px;
            color: #1f2120;
            padding: 10px 15px;
            .btn-back {
                position: unset;
                transform: matrix(-1, 0, 0, 1, 0, 0);
            }
            img {
                margin-right: 5px;
            }
        }
    }
    .total-price {
        font-size: 14px;
        line-height: 24px;
        color: #1f2120;
        padding-bottom: 35px;
        @media (max-width: 768px) {
            padding-bottom: 20px;
        }
        &__shipping,
        &__total {
            display: flex;
            justify-content: space-between;
        }
        &__total {
            margin-top: 10px;
        }
        .btn {
            width: 100%;
            margin-top: 10px;
        }
    }
    .modal-backdrop {
        width: inherit;
        height: inherit;
        background: rgb(31, 33, 32, 0.5);
        position: absolute;
        @media (max-width: 768px) {
            display: none;
        }
    }
    .modal-block {
        width: fit-content;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        right: -100%;
        animation: showModal 0.2s forwards 0.2s;
        @media (max-width: 768px) {
            z-index: 1;
        }
        @keyframes showModal {
            0% {
                opacity: 0;
                right: -100%;
            }
            100% {
                opacity: 1;
                right: 0;
            }
        }
    }
    .modal-btn {
        background: none;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        overflow: hidden;
        box-shadow: 1px 1px 6px 0 rgba(0, 45, 29, 0.05);
        margin-right: 10px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        &:hover,
        &:focus {
            opacity: 0.5;
            transition: 0.2s ease 0.2s;
        }
    }
    .modal-body {
        background-color: #fff;
        width: 470px;
        height: 100vh;
        border-radius: 16px 0 0 16px;
        box-shadow: 1px 3px 25px 0 rgba(0, 45, 29, 0.1);
        padding: 35px 20px;
        @media (max-width: 768px) {
            width: 100vw;
            height: 100vh;
            border-radius: 0;
            padding: 100px 15px 50px;
        }
        &__container {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        &__empty {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex: 1 1 auto;
        }

        &__cart-img {
            width: 242px;
            height: 235px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            @media (max-width: 768px) {
                width: 184px !important;
                height: 179px !important;
                img {
                    object-fit: contain !important;
                }
            }
        }

        &__message {
            font-family: "Nunito Sans Bold";
            font-size: 16px;
            line-height: 24px;
            color: #000;
            text-align: center;
            margin-top: 20px;
            @media (max-width: 768px) {
                font-size: 14px;
                margin-top: 35px;
            }
        }

        &__btn {
            width: 100%;
            min-height: 56px;
            border-radius: 30px;
            font-family: "Nunito Sans Bold";
            font-size: 16px;
            line-height: 24px;
            background: var(--green);
            color: #fff;
            text-align: center;
            margin-top: 20px;
            @media (max-width: 768px) {
                width: fit-content;
                margin: 0 auto;
                padding: 0 30px;
                font-size: 14px;
                min-height: 48px;
            }
        }
        &__cart-img {
            width: 242px;
            height: 235px;
            border-radius: 16px;
            overflow: hidden;
            position: relative;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
            @media (max-width: 768px) {
                height: 344px;
                width: 100%;
                .modal-body__prices {
                    position: absolute;
                    bottom: 5px;
                    left: 0;
                    right: 0;
                    width: 97%;
                    height: 40px;
                    margin: 0 auto;
                    padding: 2.5px;
                }
                .modal-body__price {
                    font-size: 12px;
                    line-height: 18px;
                    font-family: "Nunito Sans SemiBold";
                }
            }
        }

        &__bottom {
            padding-top: 10px;
        }

        &__title {
            font-size: 16px;
            line-height: 24px;
            color: #1f2120;
            font-family: "Nunito Sans Bold";
            width: 100%;
            margin-top: 20px;
            @media (max-width: 768px) {
                font-size: 14px;
            }
        }

        &__text {
            font-size: 14px;
            line-height: 24px;
            color: #1f2120;
            margin-top: 20px;
            @media (max-width: 768px) {
                font-size: 12px;
                line-height: 16px;
                font-family: "Nunito Sans ExtraLight";
            }
        }

        &__list {
            margin-top: 10px;
            li {
                font-size: 10px;
                line-height: 16px;
                color: #c8c8c8;
                -webkit-appearance: none;
                -moz-appearance: none;
                background: transparent;
                background-image: url("@/assets/img/svg/select.svg");
                background-repeat: no-repeat;
                background-position: 0;
                cursor: pointer;
                padding-left: 1rem;
                margin-top: 5px;
            }
        }

        &__prices {
            background: #f4f4f4;
            border-radius: 12px;
            width: 100%;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px;
            margin-top: 20px;
        }

        &__price {
            font-family: "Nunito Sans Bold";
            font-size: 14px;
            line-height: 22px;
            color: #7a7a7a;
            width: 25%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            cursor: pointer;
            &:hover,
            &:focus {
                background: #fff;
                color: #3b7351;
                transition: 0.2s ease 0.2s;
            }
            &.active {
                background: #fff;
                color: #3b7351;
            }
        }

        &__steps {
            display: flex;
            justify-content: space-between;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 100%;
                height: 2px;
                background: #c8c8c8;
            }
        }

        &__step {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            line-height: 18px;
            color: #fff;
            background: #c8c8c8;
            position: relative;
            &.active {
                background: #3b7351;
            }
        }
    }
    .block-payment {
        .body {
            margin-top: 20px;
            .block-input {
                input {
                    width: 100%;
                    height: 50px;
                    background: #f4f4f4;
                    border-radius: 12px;
                    margin-top: 5px;
                    padding: 20px;
                }
            }
        }
        .head-title {
            font-size: 16px;
            line-height: 24px;
            color: #1f2120;
            text-align: left;
        }
        .time-shipping {
            margin-top: 5px;
            &__btn {
                width: 50%;
                height: 40px;
                border-radius: 10px;
                box-shadow: 1px 3px 25px rgb(0, 45, 29, 0.05);
                background: #fff;
                font-size: 14px;
                line-height: 22px;
                color: #1f2120;
                font-family: "Nunito Sans Bold";
                &:hover,
                &:focus {
                    border: 1px solid var(--green);
                    color: var(--green);
                    transition: 0.2s ease 0.2s;
                }
            }
        }
        .active {
            border: 1px solid var(--green);
            color: var(--green) !important;
            .methods__title {
                color: var(--green) !important;
            }
        }
        .time-interval {
            display: flex;
            justify-content: space-between;
            margin-top: 15px;
            &__time {
                width: 30%;
                height: 40px;
                border-radius: 10px;
                box-shadow: 0 3px 35px rgb(0, 45, 29, 0.05);
                background: #fff;
                font-size: 14px;
                line-height: 22px;
                color: #1f2120;
                font-family: "Nunito Sans Bold";
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                &:hover,
                &:focus {
                    border: 1px solid var(--green);
                    color: var(--green);
                    transition: 0.2s ease 0.2s;
                }
            }
        }
        .methods {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            &__block {
                width: 49%;
                height: 112px;
                border-radius: 10px;
                box-shadow: 1px 3px 25px rgb(0, 45, 29, 0.05);
                background: #fff;
                cursor: pointer;
                &:hover,
                &:focus {
                    border: 1px solid var(--green);
                    color: var(--green);
                    transition: 0.2s ease 0.2s;
                }
            }

            &__img {
                width: 84px;
                height: 84px;
                margin: 0 auto;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &__title {
                font-size: 12px;
                line-height: 18px;
                color: #1f2120;
                font-family: "Nunito Sans Bold";
            }
        }
        .total-price {
            margin-top: 10px;
            font-size: 16px;
            line-height: 24px;
            color: #1f2120;
            &__total {
                font-family: "Nunito Sans Bold";
            }
        }
    }
    .status-order {
        margin-top: -1rem;
        .number-order {
            font-size: 18px;
            line-height: 24px;
            color: #1f2120;
            font-family: "Nunito Sans Bold";
        }
        .status-title {
            font-size: 16px;
            line-height: 24px;
            color: #1f2120;
            font-family: "Nunito Sans Bold";
            margin-top: 20px;
        }
        .personal-offers {
            &__block {
                height: 144px;
                margin-top: 20px;
            }
            &__body {
                flex: 1 1 auto;
            }
        }
        .products {
            height: 35vh;
        }
        .product {
            &__body {
                padding: 10px 15px 20px;
            }

            &__bottom {
                .count {
                    font-size: 18px;
                    line-height: 24px;
                    color: #1f2120;
                    font-family: "Nunito Sans Bold";
                }
            }
        }
        .address {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: 20px;
            border-bottom: 1px solid #f4f4f4;
            padding-bottom: 10px;
            &__title {
                font-size: 16px;
                line-height: 24px;
                color: #1f2120;
                font-family: "Nunito Sans Bold";
            }

            &__street {
                display: flex;
                flex-direction: column;
                span {
                    &:first-child {
                        font-size: 14px;
                        line-height: 24px;
                        color: #1f2120;
                    }
                    &:last-child {
                        font-size: 14px;
                        line-height: 16px;
                        color: #c8c8c8;
                    }
                }
            }
        }
        .time-order {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            border-bottom: 1px solid #f4f4f4;
            padding-bottom: 10px;
            &__order,
            &__done {
                display: flex;
                flex-direction: column;
                span {
                    &:first-child {
                        font-size: 16px;
                        line-height: 24px;
                        color: #1f2120;
                        font-family: "Nunito Sans Bold";
                    }
                    &:last-child {
                        font-size: 14px;
                        line-height: 24px;
                        color: #1f2120;
                    }
                }
            }
        }
        .total-price {
            margin-top: 10px;
            &__t-coins {
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
                span {
                    &:last-child {
                        color: var(--green);
                    }
                }
            }
            &__discounts {
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
                color: #333;
            }
        }
    }
}
#comment-order {
    width: 100%;
    height: 113px;
    background: #f4f4f4;
    border-radius: 12px;
    margin-top: 5px;
    padding: 10px;
    resize: none;
    &::placeholder {
        font-size: 14px;
        line-height: 24px;
        color: #c8c8c8;
        font-family: "Nunito Sans";
    }
}

.error {
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: var(--red);
    text-align: center;
    white-space: pre-line;
}
</style>
